var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',_vm._b({staticClass:"app-bar"},'v-app-bar',_vm.currentNavBarVOptions,false),[(_vm.showMenuButton)?_c('v-app-bar-nav-icon',{attrs:{"data-cy":"app-bar-nav-button"},on:{"click":_vm.handleClickMenu}}):_vm._e(),(_vm.showMultiPlacesMenu)?_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"wrapper-place-name"},'span',attrs,false),on),[_c('v-icon',{staticClass:"d-none d-sm-flex",attrs:{"left":""}},[_vm._v(" mdi-store-outline ")]),_c('span',{staticClass:"place-name text-subtitle-2 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.placeName)+" ")]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,false,3376902221)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":"/multi-places"}},[_c('v-list-item-title',[_vm._v("Gestionar establecimientos")]),(_vm.processingRequest)?_c('v-list-item-icon',[_c('v-progress-circular',_vm._b({},'v-progress-circular',{
              size: '24',
              width: '2',
              indeterminate: true,
              color: 'secondary'
            },false))],1):_vm._e()],1),(_vm.availablePlaces.length)?[_c('v-divider',{staticClass:"my-2"}),_vm._l((_vm.availablePlaces),function(place,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.handleClickPlace(place)}}},[_c('v-list-item-title',[_vm._v(_vm._s(place.name))])],1)})]:_vm._e()],2)],1):_c('span',{staticClass:"place-name text-subtitle-2 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.placeName))]),_c('v-spacer'),(_vm.showReferrerIcon)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.handleClickReferrerLink}},[_c('v-icon',[_vm._v("mdi-hand-heart-outline")])],1):_vm._e(),_c('div',{staticClass:"wrapper-share-menu-link"},[(_vm.showShareIcon)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.handleClickShareLink}},[_c('v-icon',[_vm._v("mdi-qrcode")])],1):_vm._e(),(_vm.showQrAlert)?_c('div',{staticClass:"wrapper-share-menu-link_bounce-arrow bounce"},[_c('v-chip',{attrs:{"color":"orange","text-color":"white","close":""},on:{"click:close":_vm.handleToggleShareLink}},[_vm._v(" Descargar código QR ")]),_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" mdi-arrow-right ")])],1):_vm._e()],1),_c('UserMenu')],1)}
var staticRenderFns = []

export { render, staticRenderFns }